import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import logoTwo from "../assets/images/lcs-logo-2.svg";
import { SET_USER_DETAIL } from "../store/actions";
import { Formik } from "formik";
import * as Yup from "yup";
import "@popperjs/core";
import axios from "axios";
import { API_URL } from "../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const Login = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const login = async (values) => {
    setErrorText(null);
    setShowLoader(true);
    try {
      const { data } = await axios.post(
        `https://survey-apis.lukecoutinho.com/corporate/employee/auth/login`,
        {
          ...values,
        }
      );
      const { user, token } = data;

      localStorage.setItem("_auth", token.token);
      const items = {
        name: user.fullName,
        email: user.email,
        age: user.age,
        gender: user.gender,
        city: user.city,
        country: user.country,
        dietPreference: user.dietPreference || "",
        preferenceDetails: user.preferenceDetails || "",
        ethnicity: user.enthicity || "",
        height: user.height || "",
        weight: user.weight || "",
        corporate_id: user.corporateId || "",
      };
      dispatch({ type: SET_USER_DETAIL, value: items });
      // dispatch({ type: SET_CURRENT_STEP, value: 4 });
      navigate("/quality-of-lifestyle-survey");
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      setErrorText(error?.response?.data?.message);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Loader />;

  return (
    <>
      <div className="container">
        <div className="mt-4 mt-md-5 welcome-logo text-center">
          <img src={logoTwo} alt="lc survey" />
        </div>

        {errorText && (
          <h3
            style={{
              margin: "10px",
              color: "red",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            {errorText}
          </h3>
        )}

        <h1 className="">
          <Formik
            initialValues={{ email: "", password: "" }}
            enableReinitialize={true}
            onSubmit={async (values) => {
              login(values);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required("Enter email")
                .email("Enter valid email"),
              password: Yup.string().required("Enter password"),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="lc-survey-form">
                    <div className="container">
                      <div className="row justify-content-center mt-5">
                        <div className="col-lg-6 col-md-8 col-sm-12">
                          <div className="form-input-sec">
                            <label htmlFor="youremail" className="form-label">
                              Your email<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Email"
                              type="text"
                              className={`form-control ${
                                touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {touched.email && errors.email && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label htmlFor="yourage" className="form-label">
                              Your password
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Password"
                              type="password"
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                            {touched.password && errors.password && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.password}
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="btn-row d-flex justify-content-center mt-2 mt-xl-3 pt-1">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-2"
                                >
                                  Login
                                </button>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="btn-row d-flex justify-content-center mt-2 mt-xl-3 pt-1">
                                <Link to="/signup">
                                  <button className="btn btn-primary btn-2">
                                    SignUp
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </h1>
      </div>
    </>
  );
};

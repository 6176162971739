import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import logoTwo from "../assets/images/lcs-logo-2.svg";

import { Formik } from "formik";
import * as Yup from "yup";
import "@popperjs/core";
import axios from "axios";
import { API_URL } from "../config/constant";
import { onlyInteger } from "../config/utils";
import Select from "react-select";
import countryData from "../config/countries.json";
import { Link, useNavigate } from "react-router-dom";

export const Signup = (props) => {
  const [errorText, setErrorText] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const countryName = Object.keys(countryData);
  const countryOptions = [];
  const navigate = useNavigate();
  countryName.map((ele) => {
    return countryOptions.push({ value: ele, label: ele, ...ele });
  });

  const availableCity =
    countryData[selectedCountry]?.map((ele) => {
      return { value: ele, label: ele, ...ele };
    }) || [];

  const initialValues = {
    fullName: "",
    gender: "M",
    age: "",
    email: "",
    password: "",
    mobileNumber: "",
    country: "",
    city: "",
    healthCondition: "",
  };

  const onSubmit = async (values) => {
    setErrorText(null);
    setShowLoader(true);
    try {
      await axios.post(
        `https://survey-apis.lukecoutinho.com/corporate/employee/auth/signup`,
        {
          ...values,
        }
      );
      navigate("/login");
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      console.log("error", error?.response?.data?.email);
      setErrorText(error?.response?.data?.message || error?.response?.data?.email);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Loader />;

  return (
    <>
      <div className="container">
        <div className="mt-4 mt-md-5 welcome-logo text-center">
          <img src={logoTwo} alt="lc survey" />
        </div>

        {errorText && (
          <h3
            style={{
              margin: "10px",
              color: "red",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            {errorText}
          </h3>
        )}

        <h1 className="">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values) => {
              onSubmit(values);
              // dispatch({ type: SET_USER_FORM_DATA, value: values });
              // dispatch({ type: SET_CURRENT_STEP, value: 4 });
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string().required("Enter full name *"),
              email: Yup.string()
                .required("Enter email *")
                .email("Enter valid email *"),
              password: Yup.string().required("Enter password *"),
              gender: Yup.string().required("Enter gender *"),
              age: Yup.number()
                .positive("Must be greater than 0")
                .max(120, "Enter valid age, lesser than 120")
                .required("Enter age *"),
              mobileNumber: Yup.string()
                .min(10, "Enter valid mobile number,  Min 10")
                .max(15, "Enter valid mobile number, Max 15 allowed")
                .required("Enter mobile number *"),
              country: Yup.string().required("Enter country *"),
              city: Yup.string().required("Enter city *"),
              healthCondition: Yup.string().required(
                "Enter health condition *"
              ),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              } = props;

              
              return (
                <form onSubmit={handleSubmit}>
                  <div className="lc-survey-form">
                    <div className="container">
                      <div className="row justify-content-center mt-5 ">
                        <div className="col-lg-6  col-md-8 col-sm-12">
                          <div className="form-input-sec">
                            <label htmlFor="fullName" className="form-label">
                              Full name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Full Name"
                              type="text"
                              className={`form-control ${
                                touched.fullName && errors.fullName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="fullName"
                              value={values.fullName}
                              onChange={handleChange}
                            />
                            {touched.fullName && errors.fullName && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.fullName}
                              </div>
                            )}
                          </div>
                          <div className="form-input-sec">
                            <label htmlFor="email" className="form-label">
                              Your email<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Email"
                              type="text"
                              className={`form-control ${
                                touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {touched.email && errors.email && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label htmlFor="password" className="form-label">
                              Your password
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Password"
                              type="password"
                              className={`form-control ${
                                touched.password && errors.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                            {touched.password && errors.password && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.password}
                              </div>
                            )}
                          </div>

                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label className="form-label d-block">
                              Select your sex
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="row inline align-items-center mt-md-3 mb-3">
                              <div className="col-md-3 col-sm-6 d-flex align-items-center me-3 me-md-3 m-bottom">
                                <input
                                  className="form-check-input radio-set mt-0 me-3"
                                  type="radio"
                                  name="gender"
                                  id="male"
                                  checked={values.gender === "M"}
                                  onChange={() => setFieldValue("gender", "M")}
                                />

                                <label
                                  className="form-check-label"
                                  htmlFor="male"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="col-md-4 col-sm-6 d-flex align-items-centerme-3 me-md-3 m-bottom">
                                <input
                                  className="form-check-input mt-0 me-3 radio-set"
                                  type="radio"
                                  name="gender"
                                  id="female"
                                  checked={values.gender === "F"}
                                  onChange={() => setFieldValue("gender", "F")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="female"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="col-md-3 col-sm-6 d-flex align-items-center">
                                <input
                                  className="form-check-input radio-set  mt-0 me-3 "
                                  type="radio"
                                  name="gender"
                                  id="other"
                                  checked={values.gender === "O"}
                                  onChange={() => setFieldValue("gender", "O")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="other"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label htmlFor="age" className="form-label">
                              Your age<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                touched.age && errors.age ? "is-invalid" : ""
                              }`}
                              name="age"
                              value={values.age}
                              onChange={handleChange}
                              onKeyDown={onlyInteger}
                              maxLength={3}
                            />
                            {touched.age && errors.age && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.age}
                              </div>
                            )}
                          </div>

                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label
                              htmlFor="mobileNumber"
                              className="form-label"
                            >
                              Mobile number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                touched.mobileNumber && errors.mobileNumber
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="mobileNumber"
                              value={values.mobileNumber}
                              onChange={handleChange}
                              onKeyDown={onlyInteger}
                            />
                            {touched.mobileNumber && errors.mobileNumber && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.mobileNumber}
                              </div>
                            )}
                          </div>

                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label className="pb-2">
                              Your country
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <Select
                              placeholder="Select your country"
                              className={`form-control px-0 ${
                                touched.country && errors.country
                                  ? "is-invalid"
                                  : ""
                              }`}
                              options={countryOptions}
                              onChange={(e) => {
                                setFieldValue("country", e?.value);
                                setSelectedCountry(e?.value);
                              }}
                            />
                            {touched.country && errors.country && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.country}
                              </div>
                            )}
                          </div>
                          <div className="form-input-sec mb-3 mb-xl-4">
                            <label className="pb-2">
                              Your city<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              placeholder="Select your city"
                              className={`form-control px-0 ${
                                touched.city && errors.city ? "is-invalid" : ""
                              }`}
                              options={availableCity}
                              onChange={(e) => setFieldValue("city", e?.value)}
                              // onChange={(e) => setSelectedCountry(e.value)}
                            />
                            {touched.city && errors.city && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.city}
                              </div>
                            )}
                          </div>

                          <div className="form-input-sec">
                            <label
                              htmlFor="healthCondition"
                              className="form-label"
                            >
                              Health condition
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              placeholder="Health Condition"
                              type="text"
                              className={`form-control ${
                                touched.healthCondition &&
                                errors.healthCondition
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="healthCondition"
                              value={values.healthCondition}
                              onChange={handleChange}
                            />
                            {touched.healthCondition && errors.healthCondition && (
                              <div
                                className="invalid-feedback"
                                style={{ fontSize: "16px" }}
                              >
                                {errors.healthCondition}
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="btn-row d-flex justify-content-center mt-2 mt-xl-3 pt-1">
                                <Link to="/login">
                                  <button className="btn btn-primary btn-2">
                                    Back
                                  </button>
                                </Link>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="btn-row d-flex justify-content-center mt-2 mt-xl-3 pt-1">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-2"
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </h1>
      </div>
    </>
  );
};
